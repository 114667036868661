import * as React from 'react';
import styles from '../Leyenda/Leyenda.css'

const Leyenda = () => {
    return(
        <div style={{marginBottom:"5%"}} className='cardLeyenda col-sm-12 col-md-8 col-lg-8 mx-auto p-3'>
            <div className='row divTit'>
                <div className="mx-auto ">
                    <h1 className='tit'>Archivo Histórico de<br/><strong>Almirante Brown</strong></h1>
                </div>
                <div className="col-12 mx-auto">
                <hr/>
                    
                      
                    <p  className='parrafo'>
                    <strong> Tiene como finalidad agrupar, organizar y preservar la documentación histórica</strong> de nuestro distrito.
                        El universo de documentos digitalizados para consulta está compuesto de diarios históricos, fotografías y digestos municipales. Este patrimonio data entre los años 1900 y 1925. 
                        </p>
                </div>
                
            </div>
        </div>
    )
}
export default Leyenda;

