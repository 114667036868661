import React from 'react';
import LogoSubseBlanco from '../images/logosubse-blanco.png';
import LogoBrownBlanco from '../images/logobrown-blanco.png';
import Iconos  from '../components/iconos/Iconos';

function Footer() {
  return (
    <div>
    

    <footer className="bg-primary bg-footer text-center text-lg-start text-white mt-auto">
        <div className="container-fluid text-center footer-fondo">
       
          <div className="row p-5 align-items-center justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 borde-footer">
            <img
              className="w-75"
              src={LogoBrownBlanco}
              alt='Logo Almirante Brown'
              style={{marginBottom: "5px"}}
            />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 borde-footer" style={{marginBottom: "5px"}}>
           
              <p>Para más información, comunicate con:</p>
              <h6>INSTITUTO DE ESTUDIOS HISTÓRICO Y</h6>
              <h6>PATRIMONIO CULTURAL DE ALMIRANTE BROWN</h6>
              <Iconos />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-7">
            <img
              className="w-100"
              src={LogoSubseBlanco}
              alt='Logo Subsecretaria Modernización'
              style={{marginBottom: "5px"}}
            />
            </div>
          </div>
        </div>
        
        <div className="text-center p-1" style={{backgroundColor: "#1077bc"}}>
          Copyright &copy; 2022- Municipalidad de Almirante Brown
        </div>

      </footer>
      </div>
  )
}

export default Footer