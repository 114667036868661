import React from 'react'
import { useState } from 'react';
import LogoBrown from '../images/logobrown.png'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Detalle from '../components/Detalle/Detalle.js';
import styled from 'styled-components'

function Info() {
    let [detalle, setDetalle] = useState('');
    let { id } = useParams();
    async function traigoInfo(){
        let info = await fetch(`/api/method/mab_frappe.api.getArchivosHistorico?numero_de_ejemplar=${id}`)
        let myJson = await info.json()
        const ArchivoHistorico = await Promise.all(myJson.message.map(async (elemento)=>{
            setDetalle(detalle={
                imagen: await Promise.all(elemento.attachments.map(async (el)=>{
                    let url = await fetch(`/api/method/mab_frappe.api.downloadAttach?name=${el.name}`)
                    return url.url
                })),
                  fecha: elemento.fecha,
                  id:elemento.id,
                  name:elemento.name,
                  nombre:elemento.nombre,
                  procedencia:elemento.procedencia,
                  tipo_de_documento:elemento.tipo_de_documento,
                  extracto:elemento.extracto,
                  lugar: elemento.lugar,
                  tema: elemento.tema
            })
        }))
      }
    useEffect(()=> traigoInfo(),[])
    
    useEffect(()=> console.log("detalle",detalle),[detalle])
  return (
    <div style={{backgroundColor: "rgba(16,119,188,0.2)"}}>
      <BarraSuperior className='w-100 h-100'>
        <a href={"/"}><Boton className="mx-4 btn" >{"<"}</Boton></a>
      </BarraSuperior>
    <div className='container row justify-content-between align-items-center py-2 px-0 justifyModify' style={{margin:"auto", flexWrap:"nowrap"}} >
      <img
        src={LogoBrown}
        alt='Logo Almirante Brown'
        className='col-lg-2 col-md-3 col-sm-3 xssFullScreen'
      />

      <TituloArchivoHistoricoDigital
        className='col-lg-7 col-md-7 col-sm-9 lggScreen px-0'
        color='#035592'
        style={{textAlign:"end"}}
      >
        ARCHIVO HISTÓRICO <strong>DIGITAL</strong>
      </TituloArchivoHistoricoDigital>
    </div>
      <Detalle detalle={detalle}  setDetailImage={null}/>
    </div>
  )
}
const BarraSuperior = styled.div`
  border-top: 40px solid #035592 ;
`;

const TituloArchivoHistoricoDigital = styled.div`
  color: ${props => props.color};
  font-size: 1.5em;
  font-weight:1.3em;
  margin-top:15px;
`;

const Boton = styled.button`
  background-color: #0076BF;
  color: white;
  border-radius: 20%;
  display:flex;
  position: absolute;
  top:1px;
  left: 1px;
`
export default Info