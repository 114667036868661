import React from "react";
import {AiOutlineFacebook} from 'react-icons/ai';
import {CgMail} from 'react-icons/cg';
import {BsTelephone} from 'react-icons/bs';
import {SiGooglemaps} from 'react-icons/si'
import  styles  from './Iconos.module.css';
import { Route } from "react-router-dom";



function Iconos() {
    return (
        <div className={styles.iconos} >
            
            <a href="https://www.google.com/maps/place/Museo+y+Archivo+Hist%C3%B3rico+%22La+Cucaracha%22/@-34.7973131,-58.3897658,15z/data=!4m5!3m4!1s0x0:0x14a3e68eb1639f0!8m2!3d-34.7973223!4d-58.3897678" 
            style={{textDeoration: "none", color:"white"}}>
            <SiGooglemaps/>
            </a>
            <a href="https://accounts.google.com/ServiceLogin/signinchooser?service=mail&passive=1209600&osid=1&continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
            style={{textDeoration: "none", color:"white"}}>
            <CgMail />
            </a>
            <a href="https://www.facebook.com/CascallaresMariano"
            style={{textDeoration: "none", color:"white"}}>
            <AiOutlineFacebook />
            </a>
            
            
            
        </div>
    )
}

export default Iconos
//<BsTelephone/>