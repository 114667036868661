import React from 'react'
import styled from "styled-components";

function Paginado({previousPage ,nextPage, start, end, results}) {

  return (
    <div className='paginado align-items-center'>
        <Boton disabled={start===0?true:false} onClick={previousPage} className='paginadoButton btn'>{"<"}</Boton>
        {
          results===1?(
            <p className="my-0 mx-2 text-center" style={{fontSize:'0.7em'}}>Mostrando registro {start+1} de {results}</p>
          ):results<9?(
            <p className="my-0 mx-2 text-center" style={{fontSize:'0.7em'}}>Mostrando {results} registros</p>
          ):(
            <p className="my-0 mx-2 text-center" style={{fontSize:'0.7em'}}>Mostrando registros del {start+1} al {end>results?results:end} de un total de {results} registros</p>
          )
        }
      
        <Boton disabled={end>=results?true:false} onClick={nextPage} className='paginadoButton btn'>{">"}</Boton>
    </div>
  )
}

export default Paginado

const Boton = styled.button`
  background-color: #0076BF;
  color: white;
`