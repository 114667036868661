import React from "react";
import './App.css'
import styled from 'styled-components'
import LogoBrown from './images/logobrown.png'
import LogoBrownBlanco from './images/logobrown-blanco.png'
import LogoSubseBlanco from './images/logosubse-blanco.png'
import Fondo from './images/fondo1.jpg'
import fondo2 from './images/fondo1.jpg';
import fondo3 from './images/fondo2.jpg';
import Buscador from "./components/Buscador";
import Leyenda from "./components/Leyenda/Leyenda";


const App = () => {
  const images = ["fondo.jpg", "fondo1.jpg", "fondo2.jpg"];
  const slideshow = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedImage , setSelectedImage] = React.useState(images[0]);
    const [loaded , setLoaded] = React.useState(false);
    const [autoPlay , setAutoPlay] = React.useState(true);
    React.useEffect(()=>{
        if(autoPlay){
            const interval = setInterval(()=>{
              siguiente()
            }, 10000)
            return () => clearInterval(interval);
        }
    })
 

    const siguiente = ()=>{
        if(slideshow.current.children.length >0){
          const primerElemento = slideshow.current.children[0];
          slideshow.current.style.transition = `1500ms`;
          const tamanioSlide = slideshow.current.children[0].offsetWidth;
          slideshow.current.style.transform = `translateX(-${tamanioSlide}px)`;
          const transicion = ()=>{
            slideshow.current.style.transition = 'none';
            slideshow.current.style.transform = 'translateX(0)';

            slideshow.current.appendChild(primerElemento)
          }
          slideshow.current.addEventListener('transitionend', transicion)
        }
    }
  return  <>
    <BarraSuperior className='w-100 h-100' />
    <div className='container row justify-content-between align-items-center py-2 px-0 justifyModify' style={{margin:"auto", flexWrap:"nowrap"}} >
      <img
        src={LogoBrown}
        alt='Logo Almirante Brown'
        className='col-lg-2 col-md-3 col-sm-3 xssFullScreen'
      />

      <TituloArchivoHistoricoDigital
        className='col-lg-7 col-md-7 col-sm-9 lggScreen px-0'
        color='#035592'
        style={{textAlign:"end"}}
      >
        ARCHIVO HISTÓRICO <strong>DIGITAL</strong>
      </TituloArchivoHistoricoDigital>
    </div>
    <ContenedorPrincipal
      imagen={Fondo}
      className="w-100"
    >

        <div onClick={siguiente} ref={slideshow} style={{display:'flex', flexWrap:'nowrap', width:'100%', height:'85vh'}}>
          <CarouselImg src={fondo2} alt="noimg" />
          <CarouselImg src={fondo3} alt="noimg"/>

        </div>
      <Buscador>
      </Buscador>
    </ContenedorPrincipal>
  </>
};

export default App;

const CarouselImg = styled.img`
min-width:100%;
min-height:87vh;
max-height:87vh;
object-fit:cover;
opacity:1;
@media screen and (max-width: 765px) {
  min-height:120vh;
max-height:120vh;
}

`

const BarraSuperior = styled.div`
  border-top: 40px solid #035592 ;
`;

const TituloArchivoHistoricoDigital = styled.div`
  color: ${props => props.color};
  font-size: 1.5em;
  font-weight:1.3em;
  margin-top:15px;
`;

const ContenedorPrincipal = styled.div`
  min-height:87vh;
  width:100%;
  overflow:hidden;
  @media screen and (max-width: 765px) {
    min-height:120vh;
  }

`;

/* const DescripcionArchivoHistorico = styled.div`
  text-align: center;
  position: absolute;
  top: 1rem;
`

const LineaDivisoria = styled.div`
  height: 2px !important;
  width: 18%;
  background-color: rgba(0,118,192,100%) !important;
`; */

/* const TextoArchivoHistorico = styled.div`
  color: white;
  text-align: center;
  font-weight: normal !important;
  width: 31%;
`; */
