import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import styled from "styled-components";
import { Box } from '@mui/system';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {AiOutlineZoomIn,AiOutlineZoomOut} from 'react-icons/ai'

const styles = theme => ({
  dialogPaper: {
    backgroundColor:'rgba(0,0,0,0.6) !important',
    backdropFilter:'blur(5px)',
  }
});

function DialogAlert({open ,handleClose ,imagenes, first, handleChangePrevious, handleChangeNext, classes}) {
  console.log('first', first);
  console.log('imagenes', imagenes);
  let {id} = useParams();
if(imagenes[0]!==undefined){
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        fullScreen={true}
        maxWidth={window.location.pathname === `/foto-historica/${id}` ? 'xl':'sm'}
        PaperProps={{ classes: {root: classes.dialogPaper } }}
      >
        <Box className="boxx w-100" sx={{height:"100vh", display:"flex",flexDirection:"column",alignItems:"center !important" ,justifyContent:"center"}}>

        <DialogActions sx={{width:"100%", display:"flex",justifyContent:"end"}}>
          <Boton className='btn px-3 butonDetail' onClick={handleClose}>
          X
          </Boton>
        </DialogActions>

        <DialogContent className='dialogImage' style={{height:'50%', display:"flex", justifyContent:"center", borderRadius:"7px"}}>
          {
            window.location.pathname === `/foto-historica/${id}`?null:(
            <button className="carousel-control-prev h-50 my-auto lgScreen lggScreen" type="button" data-bs-target="#carouselExampleIndicatorsy" data-bs-slide="prev" disabled={imagenes[0][first.number-1]?false:true} onClick={handleChangePrevious}>
              <span className="carousel-control-prev-icon ss" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>)
          }
          <div>
          <TransformWrapper defaultScale={1}>

            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div className="row mx-0 w-100">
                <div className="col-12 w-100">
              <TransformComponent>
                  <Foto key={Math.random()} src={first.img} pathName={window.location.pathname} className="d-block mx-auto pb-3" alt="..."/>
              </TransformComponent>
                </div>
                <div className="col-12 w-100 mx-0 my-2 tools">
                  <div className="row w-100 mx-0 justify-content-around">
                    <Boton style={{position:'absolute',top:'1.1%',left:'51%',width:'2rem'}} className='btn butonDetail' onClick={() => zoomIn()}><AiOutlineZoomIn/></Boton>
                    <Boton style={{position:'absolute',top:'1.1%',left:'46%',width:'2rem'}} className='btn butonDetail zoom-out-button' onClick={() => zoomOut()}><AiOutlineZoomOut/></Boton>
                  </div>
                </div>
              </div>
            )}
          </TransformWrapper>
          </div>
        {
          window.location.pathname === `/foto-historica/${id}`?null:(
          <button className="carousel-control-next lgScreen h-50 my-auto lggScreen" type="button" data-bs-target="#carouselExampleIndicatorsy" data-bs-slide="next" disabled={imagenes[0][first.number+1]?false:true} onClick={handleChangeNext}>
            <span className="carousel-control-next-icon ss" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
          )
        }
          
        </DialogContent>
        {
          window.location.pathname ===`/foto-historica/${id}`?null:(
        <DialogActions>
            <Boton className='btn mx-1 butonDetail xsScreen' style={{position:'absolute',top:'1.1%',left:'25vw'}} disabled={imagenes[0][first.number-1]?false:true} onClick={handleChangePrevious}> {'<'} </Boton>
            <Boton className='btn mx-1 butonDetail xsScreen' style={{position:'absolute',top:'1.1%',left:'65vw'}} disabled={imagenes[0][first.number+1]?false:true} onClick={handleChangeNext}>  </Boton>
        </DialogActions>
          )
        }
        </Box>
      </Dialog>
    </div>
  );
}else{
  return null
}
}
DialogAlert.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DialogAlert);

const Foto = styled.img `
  max-width: 90vw;
  max-height: 90vh;
  padding: 0 5px 0 3% !important;
`
const Boton = styled.button`
  background-color: #0076BF;
  color: white;
  border-radius: 30%;
`