import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {BiSearch, BiLoaderAlt} from "react-icons/bi";
import Cards from "./Cards/Cards";
import Paginado from "./Paginado/Paginado";
import { DatePicker } from '@material-ui/pickers';
import { format} from 'date-fns'
import { Skeleton, LinearProgress } from '@mui/material';
import Fondo1 from '../images/fondo1.jpg'
import Fondo2 from '../images/fondo2.jpg'
import Leyenda from './Leyenda/Leyenda';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/* import * as url from "url"; */
const Buscador = () => {
  const [submited, setSubmited] = useState(false);
  let [tipoDocumento , setTipoDocumento] = useState('');
  /* const [drop, setDrop] = useState(false); */
  let [loading , setLoading] = useState(false);
  let [error, setError] = useState({required:true});
  //fechas//
  let [disabled, setDisabled] = useState(true);
  let [fechaDesde, setFechaDesde] = useState(new Date('1905/01/01'));
  let [fechaHasta , setFechaHasta] = useState(new Date('1925/12/31'));
  let [disabled1, setDisabled1] = useState(true);
  //=========//
  let [busquedaAvanzada , setBusquedaAvanzada] = useState({
    nombre:"",
    tipo_de_documento:"",
    tipo_de_diario:"",
    numero_de_ejemplar:"",
    lugar:"",
    tema:"",
    extracto:"",
    fecha_desde:"",
    fecha_hasta:"",
  })
  let [informacion , setInformacion] = useState('');
  //Imagen de alta calidad => detalle.js
  //===============//
  // Paginado
  let [currentPage, setCurrentPage] = useState(1)
  let [pageSize, setPageSize] = useState(9)
  let [start, setStart] = useState(0)
  let [end, setEnd] = useState(0)
  let [results, setResults] = useState(0)
  // =========== //
  const handleChangeBusquedaInicial = (e)=>{
    if(e.target.value === 'Fotos Históricas' || e.target.value === 'Diario'){
      setBusquedaAvanzada({
        nombre:"",
        tipo_de_documento:e.target.value,
        numero_de_ejemplar:"",
        lugar:"",
        tema:"",
        extracto:"",
        fecha_desde:"",
        fecha_hasta:"",
      })
    }else{
      setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value});
    }
    setTipoDocumento(e.target.value);
  }

  const handleResetBusqueda = (e)=>{
    e.preventDefault();
    setDisabled(true);
    setDisabled1(true);
    setFechaDesde(new Date('1905/01/01'));
    setFechaHasta(new Date('1925/12/31'));
    setBusquedaAvanzada(busquedaAvanzada={
    ...busquedaAvanzada, 
    nombre:"",
    numero_de_ejemplar:"",
    lugar:"",
    tipo_de_diario:"",
    tema:"",
    extracto:"",
    fecha_desde:"",
    fecha_hasta:"",
    })
  }

  useEffect(()=>console.log(busquedaAvanzada),[busquedaAvanzada]);

  const handleSearch = async (e)=>{
    setLoading(true);
    e.preventDefault();
    setInformacion(informacion='')
    setCurrentPage(currentPage=1);
    setStart(start=0);
    setEnd(end=0);
    setResults(results=0);
    setError(error={required:true});
    let validacion = validation();
    if(validacion){
    
      traigoInfo(busquedaAvanzada).then(()=>(setLoading(false),setSubmited(true),setTimeout(() => setSubmited(false),5000)))
      setDisabled1(true);
      setDisabled(true);
    }else {
      setLoading(false);
      alert('Debe rellenar los campos de busqueda correctamente')
    }
    handleClose()
  }
  function validation(){
    setError(error={required:true})
    if(busquedaAvanzada.extracto && busquedaAvanzada.extracto.length<=3){
      setError(error={...error,nombre:'Debe tener un minimo de 3 caracteres', required:false});
    }
    return error.required;
  }

  const atach = async (el)=>{
          let url = await fetch(`/api/method/mab_frappe.api.getThumb?name=${el.attachments[0].name}`)
          let myJson = await url.json()
          return 'https://cms.brown.gob.ar'+myJson.message
  }
  async function traigoInfo(inf){
    let info = await fetch(`/api/method/mab_frappe.api.getArchivosHistorico?numero_de_ejemplar=${inf.numero_de_ejemplar}&nombre=${inf.nombre}&tipo_de_documento=${inf.tipo_de_documento}&fecha_desde=${inf.fecha_desde}&fecha_hasta=${inf.fecha_hasta}&sinopsis=${inf.extracto}&tema=${inf.tema}&lugar=${inf.lugar}`)
    let myJson = await info.json()
    let jsonFiltered = myJson.message.filter((item) => item.attachments.length > 0)
    setStart(start = (currentPage - 1) * pageSize)
    setEnd(end = currentPage * pageSize)
    
    jsonFiltered.sort(function(a, b) {
       return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
      //Los queremos ordenados por fecha, por lo tanto usamos...
     
      //En caso contrario, usariamos return b.fecha - a.fecha
      
    });
    console.log(jsonFiltered);
    for (const elemento of jsonFiltered.filter((row, index) => {
      setResults(index);

      if (index >= start && index < end) return true;
    })) {
      atach(elemento).then((res)=>{
        setInformacion(informacion  = [...informacion,{
          imagen:[res],
          fecha: elemento.fecha,
          id:elemento.id,
          name:elemento.name,
          nombre:elemento.nombre,
          procedencia:elemento.procedencia,
          tipo_de_documento:elemento.tipo_de_documento,
          extracto:elemento.extracto,
          attachments:elemento.attachments,
        }])
      })
    }
  }
  function previousPage() {
    setLoading(true);
    if (currentPage > 1){
      setCurrentPage(--currentPage)
    }
    traigoInfo(busquedaAvanzada).then(()=> setLoading(false))
  }
  function nextPage() {
    setLoading(true);
    if ((currentPage * pageSize) < results){
      setCurrentPage(++currentPage)
    }
    traigoInfo(busquedaAvanzada).then(()=> setLoading(false))
  }
// ================//
function handleCambio(e){
  setFechaDesde(e);
  setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, fecha_desde:format(e,'yyyy-MM-dd'),})
  setDisabled(false)
}
function handleCambio1(e){
  setFechaHasta(e);
  setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, fecha_hasta:format(e,'yyyy-MM-dd'),})
  setDisabled1(false)
}
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
    return (
      <>
     
      <ContenedorHeader
        imagen={Fondo1}
        className="w-100"
      >
        <Leyenda/>
      <ContenedorPrincipal className= "col-sm-12 col-md-8 col-lg-8 mx-auto p-5">
            <Contenido>
              <h4 className="p-3 xsTextAlign text-center colorTitle">Iniciá tu búsqueda</h4>
              <div className='row w-100 justify-content-center'>
                <div className="form-group d-flex justify-content-between col-md-9 col-10 px-3 mx-0">
                    <Select
                      className='form-control-plaintext px-2'
                      name='tipo_de_documento'
                      value={busquedaAvanzada.tipo_de_documento}
                      onChange={(e)=>{handleChangeBusquedaInicial(e)}}
                      style={{width:'100%'}}
                      >
                        <option value="default" hidden>Tipo de documento</option>
                        <option value='Diario'>Diarios historicos</option>
                        <option value='Fotos Históricas'>Fotografias historicas</option>
                        <option value='digesto'>Digesto</option>
                    </Select>
                <Boton disabled={loading} type="submit" onClick={(e)=>tipoDocumento==='digesto'? handleSearch(e):handleShow(e)} className='col-sm-2 col-md-3 col-xl-2 btn px-0 lgScreen'>BUSCAR </Boton>
                <Boton disabled={loading} type="submit" onClick={(e)=>tipoDocumento==='digesto'? handleSearch(e):handleShow(e)} className='col-4 btn px-0 m-0 xsScreen align-items-center justify-content-center letra'>BUSCAR</Boton>
                </div>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                        <h2 className="accordion-header xsFontSize xsTextAlign colorTitle" id="headingOne">
                            Búsqueda avanzada
                        </h2>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form className="form col-12 mx-0 justify-content-between h-100">
                      {submited && <p className="text-success mx-0 lgScreen small mt-1" style={{transition:'2s'}}>Resultados actualizados con éxito</p>}
                      {
                        tipoDocumento ==='Diario' || tipoDocumento === 'Fotos Históricas'?(
                        <div className="col-12 accordion busqueda-avanzada" id="accordionExample">
                          {/* <div className="accordion-item" style={{backgroundColor:'rgba(255,255,255,0)'}}>
                            <h2 className="accordion-header xsFontSize xsTextAlign colorTitle" id="headingOne">
                              <button className="boton-busqueda-avanzada my-3 mt-md-0 mb-md-1 accordion-button collapsed xsFontSize xsTextAlign colorTitle" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{backgroundColor:'rgba(255,255,255,0)'}}>
                                Búsqueda avanzada
                              </button>
                            </h2>
                          </div> */}
                          <div className="container">
                              {
                              busquedaAvanzada.tipo_de_documento === 'Diario'?(
                            <div className="">
                                <div className='row mx-0 p-0 d-flex w-100'>
                                  <div className='col-12 d-block justify-content-center xsFullScreen'>
                                    <Select
                                    className='form-control-plaintext mt-2 mb-2 p-2'
                                    name='nombre'
                                    value={busquedaAvanzada.nombre}
                                    onChange={(e)=>{setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value})}}
                                    style={{width:'100%'}}
                                    >
                                      <option value="default" hidden>Tipo de diario</option>
                                      <option value='El Brown'>El Brown</option>
                                      <option value='El Fiscal'>El Fiscal</option>
                                    </Select>
                                  </div>
                                  <div className='col-12 d-block justify-content-center xsFullScreen'>
                                    <Input
                                      type="number"
                                      autoComplete='off'
                                      placeholder='Número de ejemplar'
                                      name="numero_de_ejemplar"
                                      value={busquedaAvanzada.numero_de_ejemplar}
                                      onChange={(e) =>{setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value})}}
                                      className='form-control-plaintext mb-2 mt-2 p-2'
                                      style={{width:'100%', height:'20px !important', border:error.fecha?'2px solid red':''}}
                                    />
                                  </div>
                                </div>
                                <div className="row mx-0 p-0 d-flex w-100">
                                    <div className="col-6 d-block justify-content-center xsFullScreen">
                                      <h6 className='my-2 desde'>Desde:</h6>
                                      <DatePicker
                                        format='dd/MM/yyyy'
                                        animateYearScrolling={true}
                                        value={fechaDesde}
                                        autoOk={true}
                                        onChange={(e)=> handleCambio(e)}
                                        className='fecha'
                                        minDate={new Date('1905/01/01')}
                                        maxDate={new Date('1925/12/31')}
                                        style={{border:!disabled?"2px solid green":'',}}/>
                                    </div>
                                    <div className="col-6 d-block justify-content-center xsFullScreen">
                                      <h6 className='my-2' style={{width:"82%"}}>Hasta:</h6>
                                        <DatePicker
                                          format='dd/MM/yyyy'
                                          animateYearScrolling={true}
                                          value={fechaHasta}
                                          autoOk={true}
                                          onChange={(e)=> handleCambio1(e)}
                                          className='fecha'
                                          minDate={new Date('1905/01/01')}
                                          maxDate={new Date('1925/12/31')}
                                          style={{border:!disabled1?"2px solid green":'',}}/>
                                    </div>
                                </div>
                                {
                                  error.required===false?(
                                  <ul style={{color:'red', margin:'1rem 0 1rem 2rem'}}>
                                    {error.nombre?(<li>{error.nombre}</li>):null}
                                    {error.fecha?(<li>{error.fecha}</li>):null}

                                  </ul>
                                  ):null
                                }
                              <div className='row mx-0 p-0 w-100'>
                                <div className='col-6 d-block justify-content-center xsFullScreen'>
                                  <Boton disabled={loading} type="submit" onClick={(e)=>handleSearch(e)} className='col-lg-8 col-xl-10 btn px-0 mt-3 mb-3 lgScreen'>ACTUALIZAR </Boton>
                                </div>
                                <div className='col-6 d-block justify-content-center xsFullScreen'>
                                  <Boton  onClick={(e)=>handleResetBusqueda(e)} color='#F63252FF' className='col-lg-9 col-xl-10 btn px-0 mt-3 mb-3 lgScreen'>NUEVA BUSQUEDA</Boton>
                                  <Boton  onClick={(e)=>handleResetBusqueda(e)} color='#F63252FF' className='col-lg-12 btn px-0 mt-2 xsScreen justify-content-center align-items-center w-100 btnFiltro'>NUEVA BUSQUEDA</Boton>
                                  <Boton disabled={loading} type="submit" onClick={(e)=>handleSearch(e)} className='col-12 btn px-0 my-3 xsScreen justify-content-center align-items-center'>ACTUALIZAR</Boton>
                                </div>
                              </div>
                            </div>
                              ): busquedaAvanzada.tipo_de_documento === 'Fotos Históricas'?(

                              <div className="">
                                <div className='row mx-0 p-0 d-flex w-100'>
                                  <div className='col-6 d-block justify-content-center xsFullScreen'>
                                    <Select
                                      type="text"
                                      placeholder='Lugar'
                                      name="lugar"
                                      value={busquedaAvanzada.lugar}
                                      onChange={(e) =>{setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value})}}
                                      className='form-control-plaintext p-md-2 mt-2 mb-2 p-0 px-2'
                                      style={{width:'100%', height:'20px !important'}}
                                    >
                                      <option value="default" hidden>Lugar</option>
                                      <option value='Adrogué'>Adrogué</option>
                                      <option value='Francia'>Francia</option>
                                      <option value='Glew'>Glew</option>
                                      <option value='Longchamps'>Longchamps</option>
                                      {/*<option value='Ministro Rivadavia'>Ministro Rivadavia</option>*/}
                                    </Select>
                                  </div>
                                  <div className='col-6 d-block justify-content-center xsFullScreen'>
                                    <Select
                                      type="text"
                                      autoComplete='off'
                                      placeholder='Año'
                                      name="fecha_desde"
                                      value={busquedaAvanzada.fecha_desde}
                                      onChange={(e) =>{setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value})}}
                                      className='form-control-plaintext p-md-2 mt-2 mb-2 p-0 px-2'
                                      style={{width:'100%', height:'20px !important', border:error.fecha?'2px solid red':''}}
                                    >
                                      <option value="default" hidden>Año</option>
                                      <option value='01-01-1667'>1667</option>
                                      <option value='01-01-1884'>1884</option>
                                      <option value='01-01-1890'>1890</option>
                                      <option value='01-01-1897'>1897</option>
                                      <option value='01-01-1900'>1900</option>
                                      <option value='01-01-1904'>1904</option>
                                      <option value='01-01-1920'>1920</option>
                                      <option value='01-01-1921'>1921</option>
                                      <option value='01-01-1931'>1931</option>
                                      <option value='01-01-1932'>1932</option>
                                      <option value='01-01-1939'>1939</option>
                                      <option value='01-01-1940'>1940</option>
                                      <option value='01-01-1946'>1946</option>
                                      <option value='01-01-1950'>1950</option>
                                      <option value='01-01-1953'>1953</option>
                                      <option value='01-01-1956'>1956</option>
                                      <option value='01-01-1967'>1967</option>
                                      <option value='01-01-1969'>1969</option>
                                      <option value='01-01-1972'>1972</option>
                                      <option value='01-01-1975'>1975</option>
                                      <option value='01-01-2006'>2006</option>
                                      <option value='01-01-2007'>2007</option>
                                      </Select>
                                  </div>
                                </div>
                                <div className='row mx-0 p-0 d-flex w-100'>
                                  <div className='col-6 d-block justify-content-center xsFullScreen'>
                                    <Select
                                      type="text"
                                      name="tema"
                                      value={busquedaAvanzada.tema}
                                      onChange={(e) =>{setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value})}}
                                      className='form-control-plaintext p-md-2 mt-2 mb-2 p-0 px-2'
                                      style={{width:'100%', height:'20px !important'}}
                                    >
                                      <option value="default" hidden>Tema</option>
                                      <option value='Aeródromo'>Aeródromo</option>
                                      <option value='Almirante Brown'>Almirante Brown</option>
                                      <option value='Arboleda'>Arboleda</option>
                                      <option value='Casa Municipal'>Casa Municipal</option>
                                      <option value='Casonas'>Casonas</option>
                                      <option value='Castelforte'>Castelforte</option>
                                      <option value='Círculo Social Longchamps'>Círculo Social Longchamps</option>
                                      <option value='Colegio Nacional'>Colegio Nacional</option>
                                      <option value='Concursos'>Concursos</option>
                                      <option value='Día de la Primavera'>Día de la Primavera</option>
                                      <option value='Escuelas'>Escuelas</option>
                                      <option value='Estación Adrogué'>Estación Adrogué</option>
                                      <option value='Esteban Adrogué'>Esteban Adrogué</option>
                                      <option value='Ferrocarril'>Ferrocarril</option>
                                      <option value='Hipódromo'>Hipódromo</option>
                                      <option value='Hotel La Delicia'>Hotel La Delicia</option>
                                      <option value='Mástil de Plaza Brown'>Mástil de Plaza Brown</option>
                                      <option value='Palacio Municipal'>Palacio Municipal</option>
                                      <option value='Parroquia San Gabriel Arcángel'>Parroquia San Gabriel Arcángel</option>
                                      <option value='Servicio de electricidad'>Servicio de electricidad</option>
                                      <option value='Tranvía Municipal'>Tranvía Municipal</option>
                                      </Select>
                                  </div>
                                  <div className='col-6 d-block justify-content-center xsFullScreen'>
                                    <Input
                                      type="text"
                                      autoComplete='off'
                                      placeholder='Palabra Clave'
                                      name="extracto"
                                      value={busquedaAvanzada.extracto}
                                      onChange={(e) =>{setBusquedaAvanzada(busquedaAvanzada={...busquedaAvanzada, [e.target.name]:e.target.value})}}
                                      className='form-control-plaintext p-md-2 mt-2 mb-2 p-0 px-2'
                                      style={{width:'100%', height:'20px !important'}}
                                    />
                                  </div>
                                </div>
                                  {
                                    error.required===false?(
                                      <ul style={{color:'red', margin:'1rem 0 1rem 2rem'}}>
                                        {error.nombre?(<li>{error.nombre}</li>):null}
                                        {error.fecha?(<li>{error.fecha}</li>):null}

                                      </ul>
                                      ):null
                                  }
                                  <div className="row mx-0 p-0 w-100">
                                    <div className='col-6 d-block justify-content-center xsFullScreen'>
                                      <Boton disabled={loading} type="submit" onClick={(e)=>handleSearch(e)} 
                                        className='col-lg-8 col-xl-10 btn px-0 mt-3 mb-3 lgScreen' >ACTUALIZAR</Boton>
                                    </div>
                                    <div className='col-6 d-block justify-content-center xsFullScreen'>
                                      <Boton onClick={(e)=>handleResetBusqueda(e)} color='#F63252FF' className='col-lg-9 col-xl-10 btn px-0 mt-3 mb-3 lgScreen'>NUEVA BUSQUEDA</Boton>
                                      <Boton onClick={(e)=>handleResetBusqueda(e)} color="#F63252FF" className='col-1 btn px-0 mt-2 xsScreen justify-content-center align-items-center w-100 btnFiltro'>NUEVA BUSQUEDA</Boton>
                                      <Boton disabled={loading} type="submit" onClick={(e)=>handleSearch(e)} className='col-1 btn px-0 my-4 xsScreen justify-content-center align-items-center w-100'>ACTUALIZAR</Boton>  
                                    </div>
                                  </div>
                            </div>
                              ):null
                            }
                          </div>
                        </div>

                        ):null
                      }
                    </form>
                  </Modal.Body>
                </Modal>
              </div> 
            </Contenido>
          </ContenedorPrincipal>
      </ContenedorHeader>
          {
            loading?(
          <ContenedorResultados className='container containerResultados'>
          <Skeleton animation="wave" variant='text' width='40%' height={10}/>
          <LinearProgress />
          <Cards informacion={informacion} currentPage={currentPage} pageSize={pageSize}  loading={loading}/>
        </ContenedorResultados>
          ):informacion.length > 0?(
        <ContenedorResultados className='container containerResultados'>
          <h3 style={{color:'#888888'}}>{results+1} resultado/s encontrado/s</h3>
          <LineaDivisoria className='mx-0 my-3 xsLineaDivisoria'></LineaDivisoria>
          <Cards informacion={informacion} currentPage={currentPage} pageSize={pageSize} />
          <Paginado previousPage={previousPage} nextPage={nextPage} start={start} end={end} results={results+1}/>
        </ContenedorResultados>
          ) : null
        }
      </>
    );
};

export default Buscador;

const ContenedorPrincipal = styled.div`
min-height:160px;
top: 0rem;
box-shadow: rgba(0, 118, 192, 0.70) 0 2px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
margin-bottom: 100px;
background-color: rgba(255,255,255);
boder: 15px;
border-radius: 20px;
@media screen and (max-width: 600px) {
  top:2rem;
  
}
-webkit-box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
    -moz-box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
    box-shadow: 0px 0px 55px 10px rgba(0,0,0,0.73);
`;

const ContenedorHeader = styled.div`
  position: absolute;
  top:8rem;
  padding: 3rem;
  @media screen and (max-width: 1000px) {
    padding:3rem .5rem;
  }
  @media screen and (max-width: 600px) {
    top:10.5rem;
  }
  @media screen and (max-width: 500px) {
    top:10.5rem;
  }
`;

const Contenido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
    color: #035592;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  h3{
    width: 100%;
    color: #035592;
    user-select: none;
  }
  
`;

const Boton = styled.button.attrs(props => ({
  color: props.color || "#0076bf",
}))`
  background-color: ${props => props.color};
  color: white;
  border-radius: 0;
`

const Input = styled.input`
  border-radius: 0;
  background-color: rgba(162, 159, 159, 0.2);
`

const Select = styled.select`
  border-radius: 0;
  background-color: rgba(162, 159, 159, 0.2);
  color: #7C7878;
  width: 30%;
`
const ContenedorResultados = styled.div`
  min-height: 200px;
  margin-top:18rem;
  margin-bottom:2rem;
  position:relative;
  background: white;
  padding:30px 20px 30px 20px;
  @media screen and (max-width: 775px) {
    margin-top:10rem;
  }
  @media screen and (max-width: 500px) {
    margin-top:10rem;
  }
`

const ContenedorSinResultados = styled.div`
  min-height: calc(100vh - 770px) !important;
  margin-top:2rem;
  position:relative;
  background:white;
  padding:30px 20px 30px 20px;
  top:1rem;
`

const LineaDivisoria = styled.div`
  height: 2px !important;
  width: 7%;
  background-color: rgba(0,118,192,100%) !important;
`;