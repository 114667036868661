import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Info from "./Views/Info";
import DateFnsUtils from '@date-io/date-fns';
import Footer from "./Views/Footer";
import esLocale from 'date-fns/locale/es';
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";


ReactDOM.render(

    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Router>
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/archivo-historico/:id" element={<Info/>}/>
          <Route path="/foto-historica/:id" element={<Info/>}/>
        </Routes>
      </Router>
      <Footer/>
    </MuiPickersUtilsProvider>,
  document.getElementById("root")
);

reportWebVitals();
