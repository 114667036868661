import React from "react";
import styled from "styled-components";
import { Skeleton } from "@mui/material";

function Cards({ informacion, currentPage, pageSize, loading }) {
  if (loading) {
    return (
      <ContainerCards>
        {[1, 2, 3].map((element) => {
          if (element) {
            return (
              <CardInfo1 className="xsCards" key={element}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="300px"
                  animation="wave"
                />
                <Skeleton variant="text" width="100%" animation="wave" />
                <Skeleton variant="text" width="60%" animation="wave" />
              </CardInfo1>
            );
          }else{
            return null
          }
        })}
      </ContainerCards>
    );
  } else {
    return (
      <ContainerCards>
        {informacion.length > 0
          ? informacion
              .slice(
                (currentPage - 1) * pageSize,
                (currentPage - 1) * pageSize + pageSize
              )
              .map((element) => {
                if (element) {
                  let fechas = element.fecha.split("-").reverse().join("-");

                  if(element.tipo_de_documento === 'Fotos Históricas'){
                    return(
                        <a style={{maxWidth:'100%',textDecoration:'none', color:'black', display:'flex', justifyContent:'center', margin:'1em 1.5em 1em 1.5em'}} href={`/foto-historica/${element.name}`}>
                          <CardInfoHistorica
                          className="xsCards"
                            key={element.id + Math.random()}
                          >
                            <FotoHistorica src={element.imagen[0]} alt="notoma" />
                            <h6 style={{maxWidth:'100%'}}>{element.nombre.toUpperCase()}</h6>
                            <span style={{ fontSize: "13px", color: "#888888" }}>
                              {fechas}
                            </span>
                          </CardInfoHistorica>
                        </a>
                    )
                  }else{
                    return (
                      <a style={{width:'200px',textDecoration:'none', color:'black', display:'flex', justifyContent:'center', margin:'1em 4em 1em 4em'}} href={`/archivo-historico/${element.name}`}>
                        <CardInfo
                        className="xsCards"
                          key={element.id + Math.random()}
                        >
                          <Foto src={element.imagen[0]} alt="notoma" />
                          <h6>{element.nombre.toUpperCase()}</h6>
                          <span style={{ fontSize: "13px", color: "#888888" }}>
                            {fechas}
                          </span>
                        </CardInfo>
                      </a>
                    );
                  }
                }else{
                  return null
                }
              })
          : null}
      </ContainerCards>
    );
  }
}

export default Cards;

const ContainerCards = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const CardInfo = styled.div`
  width: 100%;
  min-height: 400px;
  cursor: pointer;
`;
const CardInfo1 = styled.div`
  width: 25%;
  min-height: 400px;
  margin: 1rem 2rem 1rem 2rem;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    width:55%
  }
  @media screen and (max-width: 700px) {
    width:100%
  }
`;
const Foto = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
  margin: 0px 0px 7px 0px;
`;

const FotoHistorica = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain;
  background:#ececec;
  margin: 0px 0px 7px 0px;
`;
const CardInfoHistorica = styled.div`
  width: 350px;
  min-height: 400px;
  cursor: pointer;
`;