import React from 'react'
import styled from "styled-components";


function Carousel({detalle, setDetailImage , handleClickOpen}) {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {
          detalle.imagen?
          detalle.imagen.map((e,i)=>{
            if(i===0){
              return(
                <button key={Math.random()} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} class="active" aria-current="true" aria-label={`Slide ${i}`}></button>
              )
            }else{
              return(
                <button key={Math.random()} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} aria-label={`Slide ${i}`}></button>
              )
            }
          }):null
        }

      </div>
      <div className="carousel-inner">
        {
          detalle.imagen?
          detalle.imagen.map((e,i)=>{
            if(i===0){
              return(
                <div key={Math.random()} className="carousel-item active">
                 <Foto key={Math.random()} onClick={(es)=> handleClickOpen?handleClickOpen():null} src={e} className="d-block w-100" alt="..."/>
                </div>
              )
            }else{
              return(
              <div key={Math.random()} className="carousel-item">
                <Foto key={Math.random()} onClick={(es)=> handleClickOpen?handleClickOpen():null} src={e} className="d-block w-100" alt="..."/>
              </div>
              )
            }
          }):null
        }

      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span className="carousel-control-prev-icon ss" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span className="carousel-control-next-icon ss" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carousel

const Foto = styled.img `
    width:100%;
    height:70vh;
    object-fit:contain;
    cursor:pointer;
`;

