import React, { useEffect } from 'react';
import Carousel from './Carousel';
import styled from "styled-components";
import DialogAlert from '../Dialog/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import fondo2 from '../../images/fondo1.jpg'

// alert
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
//==========
function Detalle({detalle  , setDetailImage}) {
    let {id} = useParams();
    let [copy, setCopy] = React.useState(`${window.location.href}`);
    let [copyLoading , setCopyLoading] = React.useState({state:false,load:'none'});
    let [extracto , setExtracto] = React.useState('');
    const [open, setOpen] = React.useState(false);
    let [imagenes , setImagenes] = React.useState([])
    let fechas;
    if(detalle.fecha){
        fechas = detalle.fecha.split('-').reverse().join("-");
    }

    //Alert
    const [alert, setAlert] = React.useState(false);
  
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setAlert(false);
    };
    //===========

    const handleClickOpen = async() => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleCopy = (e)=>{
        navigator.clipboard.writeText(copy)
        setCopyLoading(copyLoading={...copyLoading,state:true})
        setTimeout(()=> (setCopyLoading(copyLoading={state:false,load:'success'}),setAlert(true)),800)
    }

    let [first , setFirst] = React.useState({img:[], number:0})
    useEffect(()=>{
        setImagenes(imagenes=[detalle.imagen])
        console.log('setie img', imagenes);
        if(imagenes[0]!== undefined){
            setFirst(first={number:0,img:imagenes[0][first.number]});
            console.log('setie first', first)
        }
        setExtracto(extracto=detalle.extracto)
        if(typeof extracto === "string"){
            let index = extracto.indexOf("<p>");
            let last = extracto.indexOf("</p>");
            // let aux = extracto.substring(index+3,last)
            // setExtracto(aux);
        }
    }, [detalle])

    function handleChangeNext(){
        if(imagenes[0][first.number+1].length>2){
            setFirst(first={number:(first.number+1),img:imagenes[0][first.number+1]});
        }
    }
    function handleChangePrevious(){
        if(imagenes[0][first.number-1].length>2){
            setFirst(first={number:(first.number-1),img:imagenes[0][first.number-1]});
        }
    }
    // 58 86
    if(window.location.pathname === `/archivo-historico/${id}`){
        return(
          
      <ContainerDetail className='container '>
      
      
        <ContainerLeftDiarios>
            <div className='centerContainer' style={{width:'100%', display:'flex'}}>
            <ContainerImagenesDiarios>
                    {
                    detalle.imagen?detalle.imagen.map((e,i)=>{
                        if(i<4){
                            return (<ImagenDiarios key={Math.random()} src={e} alt='imagen'/>)
                        }
                    }):null}
                    {
                        detalle.imagen && detalle.imagen.length>4?(
                        <div style={{width:'65px', height:'11vh', background:'lightgray',display:'flex',justifyContent:'center', alignItems:'center', color:'rgb(3, 85, 146)'}}>
                            + {detalle.imagen?detalle.imagen.length-4:'no exist'}
                        </div>
                        ):null
                    }
            </ContainerImagenesDiarios>
                <div className='fullWidthCard' style={{width:'80%', height:'70vh', display:'', justifyContent:''}}>
                <DialogAlert open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} detalle={detalle} imagenes={imagenes} first={first}  handleChangeNext={handleChangeNext} handleChangePrevious={handleChangePrevious}/>
                        <Carousel detalle={detalle} setDetailImage={setDetailImage} handleClickOpen={handleClickOpen}/>
                </div>

            </div>
        </ContainerLeftDiarios>
        <ContainerMiddle>
                <div style={{width:'100%'}}>
                        <LoadingButton
                            loading={copyLoading.state}
                            onClick={handleCopy}
                            variant="contained"
                            color={copyLoading.load === 'none'?'primary':'success'}
                            >
                        Compartir
                        </LoadingButton>
                </div>

            <h3 style={{margin:'20px 0px 40px 0px', fontSize:'55px', color:"#035592", fontWeight:"1200"}} className='tituloDetalle'>{detalle.nombre?detalle.nombre.toUpperCase():null}</h3>
            <div className='row'>
                {
                    window.location.pathname === `/foto-historica/${id}`?(
                    <div className='col-md-12'>
                        <p>{extracto? extracto:null}</p>
                        <p>Tema: {detalle.tema}</p>
                        <p>Lugar: {detalle.lugar}</p>
                    </div>
                    ):null
                }
                <div className='col-md-12'>
                  <h5><small className="text-muted">Fecha:</small><span>{" "+fechas}</span></h5>
                </div>
                {
                     window.location.pathname === `/foto-historica/${id}`?null:(
                            <div className='col-md-12'>
                              <h5><small className="text-muted">Nro de ejemplar: </small><span>{detalle.id}</span></h5>
                            </div>
                     )
                }
                <div className='col-md-12'>
                  <h5><small className="text-muted">Tipo de documento:</small><span>{" "+detalle.tipo_de_documento}</span></h5>
                </div>
                <div className='col-md-12'>
                  <h5><small className="text-muted">Procedencia:</small><span>{" "+detalle.procedencia}</span></h5>
                </div>
            </div>
        </ContainerMiddle>
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
          Link copiado!
        </Alert>
      </Snackbar>
      
    </ContainerDetail>
        )
    }
  return (
    <ContainerDetail className='container'>
    {/*<ContenedorPrincipal
      imagen={fondo2}
      className="w-100"> */}
      <ContainerLeft>
            <div style={{width:'100%', height:'50vh', display:window.location.pathname === `/foto-historica/${id}`?'flex':'', justifyContent:window.location.pathname === `/foto-historica/${id}`?'center':''}}>
            <DialogAlert open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} detalle={detalle} imagenes={imagenes} first={first}  handleChangeNext={handleChangeNext} handleChangePrevious={handleChangePrevious}/>
               {
                window.location.pathname === `/foto-historica/${id}`?(
                    <Foto src={imagenes[0]} alt='noimg' onClick={handleClickOpen}/>
                ):(
                    <Carousel detalle={detalle} setDetailImage={setDetailImage} handleClickOpen={handleClickOpen}/>
                )
               }
            </div>
            {
                window.location.pathname === `/foto-historica/${id}`?(<h3 style={{margin:'20px 0px 40px 0px', fontSize:'35px', color:"#035592", fontWeight:"1200", textAlign:'center'}} className='tituloDetalle'>¡Click en la imagen para ver zoom!</h3>):(
            <ContainerImagenes>
                    {
                    detalle.imagen?detalle.imagen.map((e,i)=>{
                        if(i<6){
                            return (<Imagen key={Math.random()} src={e} alt='imagen'/>)
                        }
                    }):null}
            </ContainerImagenes>
                )

            }
        </ContainerLeft>

        <ContainerMiddle>
                <div style={{width:'100%'}}>
                        <LoadingButton
                            loading={copyLoading.state}
                            onClick={handleCopy}
                            variant="contained"
                            color={copyLoading.load === 'none'?'primary':'success'}
                            >
                        Compartir
                        </LoadingButton>
                </div>

                <h3 style={{margin:'20px 0px 30px 0px', fontSize:'45px', color:"#035592", fontWeight:"1200"}} className='tituloDetalle'>{detalle.nombre?detalle.nombre.toUpperCase():null}</h3>
            <div className='row' style={{fontSize:'18px'}} >
                {
                    window.location.pathname === `/foto-historica/${id}`?(
                    <div className='col-md-12'>
                    <h4 className='mb-3' >{extracto? extracto:null}</h4>
                      <p><small className="text-muted">Tema:</small><span> {detalle.tema}</span></p>
                      <p><small className="text-muted">Lugar:</small><span> {detalle.lugar}</span></p>
                    </div>
                    ):null
                }
                <div className='col-md-12'>
                <p><small className="text-muted">Fecha:</small> <span>{" "+fechas}</span></p>
                </div>
                {
                     window.location.pathname === `/foto-historica/${id}`?null:(
                            <div className='col-md-12'>
                            <p><small className="text-muted">Nro de ejemplar:</small><span>{detalle.id}</span></p>
                            </div>
                     )
                }
                <div className='col-md-12'>
                <p><small className="text-muted">Tipo de documento:</small><span>{" "+detalle.tipo_de_documento}</span></p>
                </div>
                <div className='col-md-12'>
                <p><small className="text-muted">Procedencia:</small><span>{" "+detalle.procedencia}</span></p>
                </div>
            </div>
        </ContainerMiddle>
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
          Link copiado!
        </Alert>
      </Snackbar>
      { /* </ContenedorPrincipal>*/}

   </ContainerDetail>
  )
}

export default Detalle



const ContainerDetail = styled.div`
width:100%;
min-height:66vh;
display:flex;
padding:2rem 2rem 0 2rem;
margin-top:3em;
padding-bottom:5rem;
@media screen and (max-width: 1000px) {
    flex-direction:column;
    align-items:center;
    padding:2rem 0.9rem 0 0.9rem
  }
`

const ContainerLeft = styled.div`
width:55%;
flex-direction:column;
@media screen and (max-width: 1000px) {
    width:100%;
  }
`
const ContainerLeftDiarios = styled.div`
width:100%;
@media screen and (max-width: 1000px) {
    width:100%;
  }
`
const ContainerMiddle = styled.div `
width:40%;
background-color:#dfdfdf;
border-radius:8px;
padding:1rem;
transition:0.7s;
&:hover{
    background-color:#ededed;
}
@media screen and (max-width: 1000px) {
    width:100%;
    margin-top:1em;
  }
`

const ContainerImagenes = styled.div `
    width:100%;
    height:15.5vh;
    display:flex;
    margin-top:10px;
    justify-content:center;
`
const ContainerImagenesDiarios = styled.div `
    width:15%;
    height:auto;
    max-height:70vh;
    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:end;
    margin:0px 10px 0 0;
    @media screen and (max-width: 500px) {
        display:none;
      }
`
const Imagen = styled.img `
    width:16.2%;
    height:100%;
    object-fit:cover;
    margin:0px 1.2px 0px 1.2px;
`
const ImagenDiarios = styled.img `
    width: 70%;
    max-height:14vh;
    object-fit:contain;
    margin:10px 1.2px 10px 1.2px;
`
const Foto = styled.img `
  width:90%;
  height:auto;
  object-fit:cover;
  @media screen and (max-width: 1000px) {
    width:100%;
    object-fit:contain;
  }
`
